html, body, div#root {
  min-height: max-content;
  height: 100%;
  width: 100%;
  background-color: #ebebeb;
  overflow: hidden;
  color: #555 !important;
}

.background, .page-container.no-nav {
  height: 100vh;
}

.fade.modal.show {
  opacity: 1;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.form-label {
  font-weight: 700;
}

.sk-fold {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotateZ(45deg);
}

.sk-fold-cube:before {
  background-color: #22d6d3 !important;
}

.card-title {
  width: 100%;
  font-weight: 700;
  margin: 0;
  padding: 15px;
  font-size: 18px;
  box-shadow: 0 5px 5px -5px gray;
}

.loading-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, 50px);
}

button>svg {
  margin-bottom: 3px;
}

.btn {
  font-weight: 500;
  text-transform: uppercase;
}

.btn-primary {
  background-color: #22d6d3 !important;
  border-color: #22d6d3 !important;
}

.btn-primary:hover:not(:disabled):not(.disabled) {
  background-color: #2cbbb9 !important;
}

.btn-primary:disabled, .btn-primary.disabled {
  background-color: #89c2c1 !important;
  border-color: #89c2c1 !important;
}

.btn-outline-primary {
  color: #22d6d3 !important;
  border-color: #22d6d3 !important;
}

.btn-outline-primary:hover:not(:disabled):not(.disabled) {
  color: #fff !important;
  background-color: #22d6d3 !important;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
  background-color: #22d6d3 !important;
  border-color: #22d6d3 !important;
}

.btn-outline-link {
  color: #555555 !important;
  border: 1px solid#555555 !important;
}

.btn-outline-link:hover:not(:disabled):not(.disabled) {
  color: #fff !important;
  background-color: #555555 !important;
}

.btn-outline-link:not(:disabled):not(.disabled).active, .btn-outline-link:not(:disabled):not(.disabled):active, .show>.btn-outline-link.dropdown-toggle {
  background-color: #555555 !important;
  border-color: #555555 !important;
}

.form-control {
  border-top: none !important;
  border-bottom: 1px solid gray !important;
  border-left: none !important;
  border-right: none !important;
}

.form-control:focus {
  border-top: none !important;
  border-bottom: 1px solid #22d6d3 !important;
  border-left: none !important;
  border-right: none !important;
  box-shadow: none !important;
}

.btn {
  font-weight: bold !important;
}

.dropdown__control {
  border-top: none !important;
  border-bottom: 1px solid gray !important;
  border-left: none !important;
  border-right: none !important;
}

.dropdown__control--is-focused {
  border-bottom: 1px solid #22d6d3 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  box-shadow: none !important;
}

.dropdown__option--is-selected {
  background-color: #22d6d3 !important;
}

.dropdown__option--is-focused {
  background-color: #c1fcfb !important;
  color: #212529 !important;
}

.dropdown__indicator-separator {
  display: none;
}

input.error, textarea.error {
  background-color: #ff00007e;
}

input, textarea, .dropdown__control {
  box-shadow: inset 0px 0px 5px rgb(216, 216, 216);
}

.dropdown__input > input {
  box-shadow: none;
}

.card {
  border-radius: .25rem !important;
}

.nav-item:hover {
  cursor: pointer;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container, .react-datepicker__input-container:focus {
  width: 100%;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  outline: none !important;
}

.datetime-picker:focus {
  outline: none !important;
}

.datetime-picker {
  width: 100%;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: gray 1px solid;
  min-height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
}

.datetime-picker:focus {
  border-bottom: #22d6d3 1px solid;
  box-shadow: none;
}

.confirmation-backdrop.modal-backdrop {
  z-index: 1055;
}

.pointer {
  cursor: pointer;
}

/* Checkbox */

.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  height: 25px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: rgb(202, 202, 202);
}

/* On mouse-over, add a grey background color */
.check-container:hover input:not(:disabled) ~ .checkmark {
  background-color: rgb(148, 148, 148);
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked:not(:disabled) ~ .checkmark {
  background-color: #22d6d3;
}

.check-container input:checked:disabled ~ .checkmark {
  background-color: #85aaa9;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 10px;
  height: 17px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav-toggle-container {
  width: 100%;
  margin: 1rem 0 0 1rem;
  display: none;
}

.nav-toggle {
  cursor: pointer;
}

svg.animated-icon {
  width: 100px;
  display: block;
  margin: 1rem auto;
}

.animated-icon > .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.animated-icon > .circle {
  -webkit-animation: dash .9s ease-in-out;
  animation: dash .9s ease-in-out;
}

.animated-icon > .line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash .9s .35s ease-in-out forwards;
  animation: dash .9s .35s ease-in-out forwards;
}

.animated-icon > .check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check .9s .35s ease-in-out forwards;
  animation: dash-check .9s .35s ease-in-out forwards;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}


@media only screen and (max-width: 750px) {
  .rbc-btn-group {
    width: 100%;
    text-align: center;
    margin: .5rem 0;
  }

  .nav-toggle-container {
    display: block;
  }
}