.facebook-btn{
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: #4267b2;
    color: white;
    border-radius: 4px;
    min-height: 38px;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
    width: fit-content;
}

.facebook-btn:hover{
    cursor: pointer;
    background-color: #335394;
}

.facebook-btn > .loader {
    display: none;
}