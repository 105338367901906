.info-row {
    min-height: 400px;
}

img {
    max-width: 94%;
}

h2 {
    margin-top: 1rem;
}

@media only screen and (max-width: 768px) {
    .info-row {
        height: auto;
        margin-bottom: 2rem !important;
        padding: 1rem 0;
    }
}