.twitter-btn{
    background-color: #1DA1F2 !important;
    color: white;
    border: none;
    min-height: 38px;
    width: fit-content;
}

.twitter-btn.btn:hover{
    cursor: pointer;
    background-color: #1c83c4 !important;
    color: white !important;
}

.twitter-btn > .loader {
    display: none;
}