.past-date {
    background-color: gray;
}

.rbc-event.rbc-selected.past-date {
    background-color: gray;
}

.rbc-calendar {
    flex-grow: 1;
}

.rbc-btn-group > button {
    border-radius: 0;
}