
.sidebar {
  transition: all .1s;
  padding-top: 60px;
}

.nav-item {
  text-decoration: none !important;
  font-size: 16px;
  color: #aaa;
  font-weight: 700;
}

.nav-item.active {
  background-color: #cffdfc;
  color: #555;
  font-weight: bold;
  border-right: 10px solid #22d6d2;
}

.sidebar.closed > .nav-item.active {
  border-right: 3px solid #22d6d2;
}

.sidebar.closed > .nav-item {
  max-width: 56px;
}

.nav-item > span {
  position: absolute;
}

.sidebar.closed > .nav-item > span {
  visibility: hidden;
  width: 0;
  overflow: hidden;
}

.nav-item:hover:not(.active) {
  background-color: #f5f5f5;
  color: #aaa;
}

.sidebar-cover {
  display: none;
}

@media (max-width: 575.98px) {
  .sidebar.closed {
      display: none;
  }

  .sidebar {
      position: absolute;
      z-index: 9;
      height: 100%;
  }

  .sidebar-cover {
      display: block;
      position: absolute;
      z-index: 8;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #555555cc;
  }

  .sidebar-cover > svg {
      position: absolute;
      z-index: 9;
      left: 290px;
      top: 15px;
  }

  .sidebar-cover > svg:hover {
      cursor: pointer;
  }
}