.info-module {
  min-height: 350px;
  max-width: 300px;
}

.content {
  height: 100%
}

.register-steps {
  width: 850px;
  padding: 20px 1rem;
  margin: 15px 0;
  align-self: center;
  max-width: 100%;
  background-color: rgba(0,0,0,0) !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #22d6d3 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #1a9694 !important;
}

@media only screen and (max-width: 850px) {
  .rs-steps-item-title {
    text-indent: -1000em;
  }
}
